import { Injectable } from '@angular/core';
// import { NotificationService } from "@progress/kendo-angular-notification";
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    // private notificationService: NotificationService,
    public globals: Globals,
    private cookieService: CookieService,
    private http: HttpClient,
  ) { }

  showCommonSuccessNotification(msg: string) {
    this.showNotification('success', msg);
  }

  showCommonErrorNotification(
    msg: string = 'Something went wrong! Please try again.'
  ) {
    this.showNotification('error', msg);
  }

  showNotification(type: notificationType, msg: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });
    Toast.fire({
      icon: type,
      title: msg
    });
  }

  showLoader() {
    if (this.globals.isLoading === false) {
      this.globals.isLoading = true;
    }
  }

  hideLoader() {
    if (this.globals.isLoading === true) {
      this.globals.isLoading = false;
    }
  }

  setCookie(name: string, data: any, isJson: boolean = false) {
    if (typeof window !== 'undefined') {
      this.cookieService.set(
        name,
        isJson ? JSON.stringify(data) : data,
        10,
        '/',
        this.globals.cookieDomainUrl
      );
    }
  }

  setPaginationCookie(name: string, data: any) {
    if (typeof window !== 'undefined') {
      this.cookieService.set(
        window.btoa(name),
        window.btoa(JSON.stringify(data)),
        1,
        '/',
        this.globals.cookieDomainUrl
      );
    }
  }

  getPaginationCookie(name: string) {
    if (typeof window !== 'undefined') {
      return this.cookieService.get(window.btoa(name))
        ? JSON.parse(window.atob(this.cookieService.get(window.btoa(name))))
        : null;
    } else {
      return null;
    }
  }

  clearPaginationCookie(name: string) {
    if (typeof window !== 'undefined') {
      if (this.cookieService.get(window.btoa(name))) {
        this.cookieService.delete(
          window.btoa(name),
          '/',
          this.globals.cookieDomainUrl
        );
      }
    }
  }

  getCookie(name: string, isJson: boolean = false) {
    if (typeof window !== 'undefined') {
      return this.cookieService.get(name)
        ? isJson
          ? JSON.parse(this.cookieService.get(name))
          : this.cookieService.get(name)
        : null;
    } else {
      return null;
    }
  }

  clearLoginCookie() {
    this.globals.isLoggedIn = false;
    this.clearCookie(this.globals.cookieLoginTokenName);
    this.clearCookie(this.globals.cookieUserDataName);
    this.clearPaginationCookie('my-interest');
    this.clearPaginationCookie('my-alert');
  }

  clearCookie(name: string) {
    if (typeof window !== 'undefined') {
      this.cookieService.delete(
        name,
        '/',
        this.globals.cookieDomainUrl
      );
    }
  }

  // Encrypt the data
  encrypt(data: string): string {
    const encrypted = CryptoJS.AES.encrypt(data, this.globals.encryptionKey).toString();
    return this.base64UrlEncode(encrypted);
  }

  // Decrypt the data
  decrypt(data: string): string {
    const decoded = this.base64UrlDecode(data);
    const bytes = CryptoJS.AES.decrypt(decoded, this.globals.encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  // Encode Base64 string to be URL-friendly
  private base64UrlEncode(str: string): string {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  // Decode URL-friendly Base64 string
  private base64UrlDecode(str: string): string {
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    while (str.length % 4) {
      str += '=';
    }
    return str;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
      .then(() => {
        this.showCommonSuccessNotification('"' + text + '" Copied!')
      }).catch(
    );
  }

  browserDetectLog() {
    // const entity = this.globals.browserInfo;
    // return new Promise((resolve, reject) => {
    //   firstValueFrom(this.http.post(this.globals.baseAPIUrl + '/add/browser-logs', entity)).then(
    //     (res: any) => {
    //     },
    //     (msg) => {

    //     }
    //   );
    // });
  }

  getUserInfo() {
    return new Promise((resolve, reject) => {
      firstValueFrom(
        this.http.get(this.globals.baseAPIUrl + '/client/get-user-data')
      ).then(
        (res: any) => {
          if (res.data) {
            this.globals.authData = res.data;
            this.globals.userRole = this.globals.authData.role_id;
            this.setCookie(this.globals.cookieUserDataName, this.globals.authData, true);
          }
          resolve(res);
        },
        (msg) => {
          reject(msg);
        }
      );
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      firstValueFrom(
        this.http.get(this.globals.baseAPIUrl + '/client/get-user-data')
      ).then(
        (res: any) => {
          this.clearLoginCookie();
          resolve(res);
        },
        (msg) => {
          reject(msg);
        }
      );
    });
  }


}

export type notificationType =
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'question';
